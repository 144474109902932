.gallery_container{
    position: relative;
    width: 100%;
    height: 250vh;
    overflow: hidden;
    background-color: black;
}
.gallery_main{
    position: relative;
    width: 100%;
    height: 120%;
    margin-top: -100px;
    margin-left: -150px;
    transform: rotate(-10deg);
    background: pink;
    background-image: url(../images/cat\ in\ car.png);
    background-size: cover;
    background-position: center;
}
.gallery_leftSide{
    position: absolute;
    width: 35%;
    height: 100%;
    background-color: black;
}
.gallery_rightSide{
    position: absolute;
    right: 0;
    width: 35%;
    height: 100%;
    background-color: black;
}
.gallery{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.952);
    backdrop-filter: blur(2px);
}
.gallery_logo{
    position: absolute;
    width: 200px;
    height: 80px;
    transform: rotate(10deg);
    top: 0;
    left: 5%;
}
.gallery_logo img{
    width: 100%;
    height: 100%;
}
.gallery_content{
    position: absolute;
    top: 23%;
    right: 0;
    width: 90%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left_content{
    width:45%;
    color: white;
    height: 90%;
}
.left_content h1{
    font-weight: lighter;
    line-height: 6vh;
    font-size: 1.7em;
    letter-spacing: -3px;
    text-shadow:0px 0px 7px #319ad7,0px 0px 7px #61d5f3;
    font-family: 'Courier New', Courier, monospace;
}
.left_content p:nth-child(2){
    font-size: 1.3em;
    margin: 50px 0 20px;
    font-family: 'Courier New', Courier, monospace;
    color:white;
}
.left_content p:nth-child(3){
    font-size: 1.3em;
    line-height: 6vh;
    font-family: 'Courier New', Courier, monospace;
    color:white;
}
.left_content p:nth-child(4){
    font-size: 1.3em;
    margin: 40px 0;
    line-height: 6vh;
    color: white;
}
.right_content{
    position: relative;
    width:50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .CEO_container{
    width:140px;
    height:130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    color: white;
    transform: translateX(-50%);
    bottom: 5%;
}
.CEO_content2{
    position: absolute;
    text-shadow:0px 5px 7px #2daaf3;
    font-size: 1.3em;
    bottom: 20%;
    font-family: 'Courier New', Courier, monospace; 
}
.CEO{
    width:100px;
    height:100px;
    border: 2px solid white;
    border-radius: 50%;
    overflow: hidden;
}
.CEO img{
    object-fit: cover;
    width:100%;
    height: 100%;
} */
.slider_container{
    position: relative;
    width: 50%;
    height: 80%;
    margin: 40px;
}
.button{
    position: absolute;
    width: max-content;
    background: #0F0F0F;
    height: 20px;
    width: 20px;
    padding: 10px 10px;
    font-size: 2em;
    bottom: 0;
    right: -80px;
    color: white;
    cursor: pointer;
}
.button1{
    left: -80px;
}
.slide{
    position: absolute;
    width: 100%;
    height: 100%;
}
.slide1{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.523); */
    filter: blur(20px);
}
.slide1::after{
    position: absolute;
    content: '';
    width: 50%;
    height: 5%;
    filter: blur(30px);
    border-radius: 50%;
    left: 50%;
    top: 115%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.523);
}
.slide img{
    width:100%;
    height:100%;
    object-fit: cover;
}
.slider_container .slide:nth-child(1){
    transform: rotate(6deg);
    border:1px solid #019df7;
}
.slider_container .slide:nth-child(2){
    transform: rotate(0deg);
}
.slider_container .slide:nth-child(3){
    border:1px solid #019df7;
    transform: rotate(-3deg);
}
.slider_container .slide:nth-child(4){
    transform: rotate(2deg);
    border:1px solid #019df7;
}
.slider_container .slide:nth-child(5){
    transform: rotate(-2deg);
}
.slider_container .slide:nth-child(6){
    border:1px solid #019df7;
}
.slider_container .active{
    z-index:111;
    transform: rotate(0deg) !important;
}
@media screen and (max-width:768px) {
    .gallery_content{
        top: 25%;
        width: 100%;
    }
    .left_content{
        width: 36%;
    }
    .left_content h1{
        font-size: 1.5em;
    }
    .left_content p:nth-child(2){
        font-size: 1.1em;
    }
    .left_content p:nth-child(3){
        font-size: 1.1em;
    }
    .left_content p:nth-child(4){
        font-size: 1.1em;
    }
    .right_content{
        width: 60%;
    }
}
@media screen and (max-width:426px) {
    .gallery_content{
        top: 30%;
        width: 100%;
        height: 120vh;
        flex-direction: column;
    }
    .left_content{
        width: 90%;
    }
    .left_content h1{
        font-size: 1.5em;
    }
    .left_content p:nth-child(2){
        font-size: 1.1em;
    }
    .left_content p:nth-child(3){
        font-size: 1.1em;
    }
    .left_content p:nth-child(4){
        font-size: 1.1em;
    }
    .right_content{
        width: 100%;
    }
    .slider_container{
        width: 60%;
    }
}
@media screen and (max-width:376px) {
    .left_content{
        width: 90%;
    }
    .button{
        right: -65px;
    }
    .button1{
        left: -65px;
    }
}
@media screen and (max-width:320px) {
    .button{
        padding: 5px 5px;
        right: -55px;
    }
    .button1{
        left: -55px;
    }
}