*{
    margin: 0;
    padding: 0;
}
.main_container{
    position: relative;
    width: 100%;
    height: 130vh;
    background-image:url(../images/homeBanner.png);
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}
.btn_container{
    left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
    position: absolute;
}
.btn_container button{
    width: max-content;
    padding: 15px 60px;
    font-size: 1.3em;
    background-color: black;
    color: #008DE1;
    border-radius: 25px;
    z-index: 111;
    cursor: pointer;
    box-shadow: -2px -3px 2px #999;
}
.btn_container button:hover{
    background-color: #008DE1;
    color: black;
    border: none;
}
.btn_container:hover div{
    background: #0b99eb;
}
.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.432);
    backdrop-filter: blur(20px);
    border-top: 1px solid #333;
    color: #999;
    letter-spacing: -2px;
    font-family: 'Courier New', Courier, monospace;
}
.icons_container{
    position: absolute;
    width:22.5%;
    top: 51.2%;
    right: 6.2%;
    transform: rotate(1deg);
    height: 6%;
    display:flex;
    align-items: center;
    justify-content: center;
    background: rgba(128, 128, 128, 0.112);
}
.icons_container img{
    cursor: pointer !important;
    width: 80%;
    height: 80%;
    margin: 2px;
}
.soldOut_container{
    position: absolute;
    top: 52%;
    right: 14.5%;
    width: 250px;
    height: 120px;
}
.soldout{
    position: absolute;
    width: 32%;
    height: 50%;
    display: block;
}
.mintday{
    position: absolute;
    width: 100%;
    height: 100%;
    top: -30px;
    left: -85px;
    display: none;
}
@media screen and (max-width:1024px) {
    .icons_container{
        width:24.5%;
        right: 0%;
    }
    .soldOut_container{
        right: 9%;
    }
    .icons_container img{
        width: 70%;
        height: 70%;
    }
}
@media screen and (max-width:768px) {
    .icons_container{
        width:16%;
        right: 0%;
    }
    .icons_container img{
        width: 70%;
        height: 70%;
    }
    .soldOut_container{
        right: -40px;
    }
}
@media screen and (max-width:426px) {
    .icons_container{
        width: 15%;
        height: 15%;
        flex-direction: column;
    }
    .icons_container img{
        width: 80%;
        height: 80%;
    }
    .soldOut_container{
        top:30%;
        right: -150px;
    }
    .soldout{
        display: none;
    }
    .mintday{
        display: block;
    }
}
@media screen and (max-width:415px) and (max-height:897px) {
    .soldOut_container{
        top:30%;
        right: -210px;
    }
}
@media screen and (max-width:415px) and (max-height:737px) {
    .soldOut_container{
        top:30%;
        right: -170px;
    }
}
@media screen and (max-width:391px) {
    .soldOut_container{
        top:30%;
        right: -205px;
    }
}
@media screen and (max-width:376px) and (max-height:668px) {
    .soldOut_container{
        top:30%;
        right: -175px;
    }
}
@media screen and (max-width:320px) and (max-height:481px)  {
    .icons_container img{
        width: 80%;
        height: 80%;
    }
    .footer{
        text-align: center;
    }
    .soldOut_container{
        top:30%;
        right: -160px;
    }
}
