.main_container{
    width: 100%;
    height: 130vh;
    background:url(../images/homeBanner.png);
    background-size: cover;
    background-position: center;
}
.container_main{
    position: relative;
    padding: 1px;
    width: 100%;
    height: 99.7%;
    background-color: rgba(0, 0, 0, 0.726);
    backdrop-filter: blur(20px);
}
.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    border-top: 1px solid #333;
    color: #999;
    letter-spacing: -2px;
}
.manifesto_container{
    position: absolute;
    top: 25%;
    left: 20%;
    width: 50%;
    height: 60%;
}
.manifesto_heading{
    position: relative;
    width: 150px;
    height: 60px; 
    background: radial-gradient(rgba(255, 255, 255, 0.217),transparent 75%);
}
.manifesto_heading img{
    width: 100%;
    height: 100%;
    position: absolute;
}
.manifesto_heading p{
    position: absolute;
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 2em;
    color: white;
    text-shadow: 2px 2px 5px rgb(7, 104, 215);
}
.manifesto_content{
    color: #0175BA;
    margin-top: 50px;
    font-size: 1em;
    line-height: 4vh;
}
.arrow_container{
    width: 400px;
    height: 250px;
    position: absolute;
    top: 45%;
    right: 10%;
    overflow: hidden;
}
.arrow_container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.arrow_container img:nth-child(1){
    display: block;
}
.arrow_container img:nth-child(2){
    display: none;
}
.arrow_container:hover img:nth-child(1){
    display: none;
}
.arrow_container:hover img:nth-child(2){
    display: block;
}
@media screen and (max-width:768px) {
    .manifesto_container{
        left: 10%;
    }
    .arrow_container{
        width: 300px;
        height: 200px;
    }
}
@media screen and (max-width:426px) {
    .container_main{
        height: 120%
    }
    .manifesto_container{
        left: 20%;
        width: 70%;
        height: 60%;
    }
    .arrow_container{
        display: none;
    }
}
@media screen and (max-width:376px) {
    .container_main{
        height: 110%
    }
    .manifesto_container{
        top: 15%;
        left: 18%;
        width: 70%;
        height: 60%;
    }
}
@media screen and (max-width:320px) {
    .container_main{
        height: 120%
    }
    .manifesto_container{
        left: 25%;
        top: 13%;
        width: 70%;
        height: 60%;
    }
}