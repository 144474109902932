.logo{
    top: 0;
    left: 50%;
    width: 110px;
    height: 110px;
    cursor: pointer;
    position: absolute;
    transform: translateX(-50%);
}
.logo img{
    width: 100%;
    height: 100%;
}
.text_container{
    margin-top: 50px;
}
.lightText{
    width: 45%;
    right: 0%;
    position: absolute;
    display: block;
}
.text_container:hover .lightText{
    display: none;
}
.brightText{
    right: -2%;
    position: absolute;
    display: none;
}
.text_container:hover .brightText{
    width: 47%;
    display: block;
}
@media screen and (max-width:1024px) {
    .text_container{
        margin-top: 85px;
    }   
}
@media screen and (max-width:768px) {
    .text_container{
        margin-top: 115px;
    }   
}
@media screen and (max-width:426px) {
    .text_container{
        margin-top: 135px;
    }   
    .lightText{
        width: 70%;
    }
    .text_container:hover .brightText{
        width: 72%;
        display: block;
    }
}
@media screen and (max-width:376px) {
    .text_container{
        display: none;
    }   
}