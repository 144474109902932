.main_navbar{
    position: absolute;
    width: 5%;
    height: 190px;
    top: 200px;
    left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.items{
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #008DE1;
}
.item_content{
    color: #008DE1;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
}
.item{
    position: absolute;
    color: white;
    top: 60%;
    visibility: hidden;
    opacity: 0;
    left: 120%;
    padding: 10px 30px;
    border: none;
    background-color: rgba(255, 255, 255, 0.096);
    color: white;
    border-radius: 5px;
    transition: .5s ease;
}
.items:hover .item{
    top: 50%;
    visibility: visible;
    opacity: 1;
    transform: translateY(-50%);
}
@media screen and (max-width:426px) {
    .main_navbar{
        left: 5%;
    }
}
@media screen and (max-width:320px) {
    .main_navbar{
        left: 10%;
    }
}