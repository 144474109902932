*{
    margin: 0;
    padding: 0;
}
.journey_container{
    position: relative;
    width: 100%;
    height: 200vh;
    background:linear-gradient(to top,black,transparent 70%),url(../images/cat\ in\ car.png);
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}
.journey_footer{
    position: absolute;
    width: 100%;
    height: 80px;
    bottom: 0;
    color: #999;
    background: black;
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content_container{
    width: 80%;
    height: 400px;
    position: absolute;
    bottom: 5%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%);
}
.button_container{
    width: 100%;
    height: 20%;
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_container button{
    margin: 20px;
    font-size: 1.2em;
    padding: 15px 40px;
    background: grey;
    color: white;
    border: 3px solid white;
}
.button_container button:nth-child(1){
    background: transparent;
    border: 3px solid #208ac8;
    color: #208ac8;
    text-shadow: 1px 1px 3px white;
    box-shadow: 0 0 5px 0 white;
    transition: .3s ease;
    cursor: pointer;
}
.button_container button:hover:nth-child(1){
    background: #208ac8;
    color: white;
    box-shadow: 0 0 5px 0 #208ac8;
    border: 3px solid white;
}
.content_div{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    width: 70%;
    height: max-content;
    color: white;
    background: black;
    z-index: 11;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.content_div > span{
    position: absolute;
    top: 3%;
    right: 2%;
    font-size: 1.5em;
    cursor: pointer;
}
.main_journey_heading{
    width: 100%;
    height:10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.journey_heading{
    position: relative;
    width: 200px;
    height: 90%;
    background: radial-gradient(rgba(255, 255, 255, 0.217),transparent 85%);
}
.journey_heading img{
    width: 100%;
    height: 100%;
    position: absolute;
}
.journey_heading p{
    position: absolute;
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1.8em;
    color: white;
    text-shadow: 2px 2px 5px rgb(7, 104, 215);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}
.main_journey_heading span{
    margin: 0 20px;
}
.journey_content{
    width:85%;
    height: max-content;
    margin: 1% auto;
    font-size: .9em;
    line-height: 3.3vh;
    color:#208ac8;
}

.journey_content div{
    text-align: center;
    font-size: 1.1em;
    color:white;
}

.journey_content bold{
    color:white;
}

.journey_content p{
    text-align: center;
}

.journey_content span{
    font-weight:bold;
}

.signature_container{
    margin: auto;
    width: 200px;
    height: max-content;
    text-align: center;
}
.signature_container p{
    font-size: 1em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.signature_container span{
    position: relative;
    font-size: .7em;
    z-index: 1;
}
.signature_container div{
    width: 70px;
    height: 100px;
    margin: auto;
    z-index: -1;
    transform:rotate(-90deg);
}
.signature_container div img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1024px) {
    .journey_content{
        width: 95%;
    }
    .journey_container{
        height: 210vh;
    }
}
@media screen and (max-width:768px) {
    .button_container button{
        margin: 10px;
        padding: 15px 40px;
    }
    .content_div{
        width: 90%;
    }
    .journey_container{
        height: 250vh;
    }
}
@media screen and (max-width:426px) {
    .button_container{
        flex-direction: column;
    }
    .journey_container{
        height: 290vh;
    }
    .content_div{
        width: 100%;
    }
}
@media screen and (max-width:376px) {
    .journey_container{
        height: 195vh;
    }
    .journey_container{
        height: 310vh;
    }
}
@media screen and (max-width:320px) {
    .journey_footer{
        text-align: center;
    }
    .journey_container{
        height: 330vh;
    }
}